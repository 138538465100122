import React from "react"
// eslint-disable-next-line
import style from '../stylesheets/main.scss'
/* import style from '../stylesheets/index.css' */
import Navi from '../components/navi';
import Footer from '../components/footer';
import { graphql } from "graphql";

class Layout extends React.Component {
  render() {
/*     const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/` */
    const { children } = this.props
    let backButton = function() { window.history.back() }
    return (
      <div className="mx-auto w-1/1 lg:w-7/12">
      <a className="cursor-pointer" onClick={ backButton }>Go back &larr;</a>
        {children}
        <Footer></Footer>
      </div>
    )
  }
}

export default Layout
