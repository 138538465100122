import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/blogpost-layout"
import SEO from "../components/seo"

import { DiscussionEmbed } from "disqus-react";

import "katex/dist/katex.min.css"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    /* const siteTitle = this.props.data.site.siteMetadata.title */
    //const { previous, next } = this.props.pageContext
    /* const disqusShortname = "niksa-mihaica-stories"; */
    /* const disqusConfig = {
      url: `https://nmihaica.gitlab.io${this.props.location.pathname}`,
      identifier: post.id,
      title: post.frontmatter.title,
    }; */

    /* const header = `
    <h1 id="tufte-css">${post.frontmatter.title}</h1>
    <p class="subtitle">${post.frontmatter.date}</p>
    ` */
    return (
      <Layout location={this.props.location}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />         
        <h1 id="blogpost-title" className="font-bold my-12 break-words">{post.frontmatter.title}</h1>
        <p className="mt-4 mb-2 text-gray-600">{post.frontmatter.date}</p>
        {/* <hr></hr> */}
        <article className="markdown-body" dangerouslySetInnerHTML={{ __html: post.html }}>
        </article>
        <div className="text-gray-600 mt-4">
          { post.frontmatter.last_modification !== null ? 
          <i>Last edit on {post.frontmatter.last_modification}</i>
          : <i></i>
          }
        </div>

      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        last_modification
        attachments {
          publicURL
        }
      }
    }
  }
`
